<template>
  <form
    ref="filesForm"
    class="app-upload__form"
    @submit.prevent="uploadImages"
  >
    <label for="files" class="app-upload__label" />
    <input
      id="files"
      ref="files"
      :disabled="loading"
      class="app-upload__input"
      type="file"
      name="files"
      accept="image/png, image/jpeg, image/webp, video/mp4, video/x-m4v, video/*, image/heic"
      multiple
      @input="uploadImages"
    >
  </form>
</template>

<script>
  import { useToastStore } from '~/stores/toast'
  import { Event } from '~/lib/models/event'
  import { EVENT_PROPERTY_KEY } from '~/lib/constants/event'
  import { CONNECTOR } from '~/lib/constants/connectors'

  export default {
    props: {
      event: {
        type: Event,
        required: true,
      },
    },
    data() {
      return {
        loading: false,
      }
    },
    computed: {
      eventId() {
        return this.$route.params.event
      },
      useGoogleDrive() {
        return this.$eventService.getEventPropertyByKey(this.event.properties, EVENT_PROPERTY_KEY.USE_GOOGLE_DRIVE)?.value
      },
      useDropbox() {
        return this.$eventService.getEventPropertyByKey(this.event.properties, EVENT_PROPERTY_KEY.USE_DROPBOX)?.value
      },
      useHosting() {
        return this.$eventService.getEventPropertyByKey(this.event.properties, EVENT_PROPERTY_KEY.HOSTING_ENABLED)?.value
      },
      galleryEnabled() {
        return this.$eventService.getEventPropertyByKey(this.event.properties, EVENT_PROPERTY_KEY.ENABLE_GALLERY)?.value
      },
      connector() {
        if (this.useGoogleDrive) {
          return CONNECTOR.GOOGLE_DRIVE
        }

        if (this.useDropbox) {
          return CONNECTOR.DROPBOX
        }

        return undefined
      },
      largeVideosAllowed() {
        return this.$eventService.getEventPropertyByKey(this.event.properties, EVENT_PROPERTY_KEY.LARGE_VIDEOS_ALLOWED)?.value
      },
    },
    methods: {
      showToastMessage(message) {
        useToastStore().showToastMessage(message)
      },
      async uploadImages() {
        if (this.useGoogleDrive || this.useDropbox) {
          return this.handleUploadConnectorImages()
        }

        return this.handleUploadImages()
      },
      async handleUploadImages() {
        const files = this.$refs.files.files

        if (!files.length) {
          return
        }

        if (!this.useHosting) {
          await this.showToastMessage({
            text: this.$t('no_valid_storage_connected'),
            duration: 10000,
          })
          return
        }

        this.loading = true

        try {
          await this.$imagesService.uploadImages(this.eventId, files, this.largeVideosAllowed)
        } catch (error) {
          await this.showToastMessage({
            text: error.message ? error.message : error,
          })
        } finally {
          this.$refs.files.value = ''
        }

        if (this.galleryEnabled) {
          await this.$imagesService.getImages({ event: this.event })
        }

        this.loading = false
      },
      async handleUploadConnectorImages() {
        const files = this.$refs.files.files

        try {
          await this.$imagesService.uploadImagesToConnector(this.eventId, files, this.connector, this.largeVideosAllowed)
        } catch (error) {
          await this.showToastMessage({
            text: error.message ? error.message : error,
          })
        }

        if (this.galleryEnabled) {
          await this.$imagesService.getImages({ event: this.event })
        }
      },
    },
  }
</script>

<style lang="scss">
  .app-upload__form {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-upload__label {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .app-upload__input {
    display: none;
  }
</style>
