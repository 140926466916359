<template>
  <div
    ref="imageModal"
    class="app-images-modal"
  >
    <div
      ref="imageModalImages"
      class="app-images-modal__images"
      @touchend="handleTouch"
    >
      <template v-for="(image, imageIndex) in images" :key="image.id">
        <app-images-modal-item
          class="app-images-model__media-item-wrapper"
          :image="image"
          :index="index"
          :image-index="imageIndex"
          :is-google-drive="isGoogleDrive"
          :is-momentshare-drive="isMomentshareDrive"
          :event-name="event.name"
          :event-id="event.id"
          @like="like(image)"
          @unlike="unlike(image)"
        />
      </template>
      <div
        v-show="hasMorePages"
        ref="endlessScrollElement"
        class="app-images-modal__endless-scroll"
      >
        <app-spinner
          class="app-images-modal__endless-scroll-spinner"
          dimension="32px"
          color="white"
        />
      </div>
    </div>

    <div class="app-images-modal__background" />

    <div class="app-images-modal__controls">
      <button
        class="app-images-modal__button app-images-modal__button--back"
        @click="$emit('close')"
      >
        <icon
          name="bx:arrow-back"
          class="app-images-modal__icon app-images-modal__icon--back"
        />
        {{ $t('overview') }}
      </button>
      <start-slideshow-button
        v-if="isMomentshareDrive"
        class="app-images-modal__start-slideshow"
        name="mdi:presentation-play"
        inverted
        @click="$emit('startSlideshow')"
      />
    </div>

    <transition name="fade">
      <scroll-indicator v-if="!isTouched && isMobile" class="app-images-modal__scroll-indicator" />
    </transition>
    <text-logo show-extension class="app-images-modal__text-logo" />
  </div>
</template>

<script setup>
  import { Event } from '~/lib/models/event'
  import { storeToRefs } from 'pinia'
  import { useImagesStore } from '~/stores/images'
  import { useMainStore } from '~/stores/main'
  import { useOverlayStore } from '~/stores/overlay'

  const { $interactionsService, $imagesService } = useNuxtApp()

  const props = defineProps({
    event: {
      type: Event,
      default: undefined,
    },
    index: {
      type: Number,
      default: 0,
    },
    eventName: {
      type: String,
      required: true,
    },
    eventId: {
      type: String,
      required: true,
    },
    isGoogleDrive: {
      type: Boolean,
      default: false,
    },
    isMomentshareDrive: {
      type: Boolean,
      default: false,
    },
  })

  const mainStore = useMainStore()
  const { isMobile } = mainStore
  // const videoObserver = ref(null)
  const endlessScrollObserver = ref(null)
  const isTouched = ref(false)
  const isInitiallyScrolled = ref(false)
  const imageModal = ref(null)
  const imageModalImages = ref(null)
  const loadingNewImages = ref(false)
  const endlessScrollElement = ref(null)
  // const videoPromises = new Map()

  defineEmits(['close', 'startSlideshow'])

  const imagesStore = useImagesStore()
  const overlayStore = useOverlayStore()
  const {
    images,
    hasMorePages,
  } = storeToRefs(imagesStore)

  onMounted(async () => {
    overlayStore.setEventImagesModalIsActive(true)
    await nextTick()
    imageModal.value.style.top = document.querySelector('.event-layout').scrollTop + 'px'
    // setIntersectionObserversOnVideos()
    setIntersectionObserversOnEndlessScroll()
    scrollToClickedIndex()
  })

  onBeforeUnmount(() => {
    // videoObserver.value?.disconnect()
    // videoObserver.value = null
    endlessScrollObserver.value?.disconnect()
    endlessScrollObserver.value = null
    overlayStore.setEventImagesModalIsActive(false)
    // videoPromises.clear()
  })

  async function fetchNewPage() {
    loadingNewImages.value = true

    await $imagesService.fetchNewPage({ event: props.event })

    await nextTick()

    loadingNewImages.value = false
  }

  function handleTouch() {
    if (!isInitiallyScrolled.value) {
      return
    }

    if (isTouched.value) {
      return
    }

    isTouched.value = true
  }

  function scrollToClickedIndex() {
    if (props.index !== 0) {
      document.querySelectorAll('.app-images-model__media-item-wrapper')[props.index].scrollIntoView({
        block: 'center',
      })
    }

    isInitiallyScrolled.value = true
  }

  // function setIntersectionObserversOnVideos() {
  //   videoObserver.value = new IntersectionObserver((entries) => {
  //     entries.forEach(entry => {
  //       if (entry.target.tagName.toLowerCase() !== 'video') {
  //         return
  //       }
  //
  //       // Store video element
  //       const video = entry.target
  //
  //       if (entry.intersectionRatio === 1 && video.paused) {
  //         // Store video play promise
  //         const videoPlayPromise = video.play()
  //
  //         videoPromises.set(video, videoPlayPromise)
  //       } else {
  //         const videoPlayPromise = videoPromises.get(video)
  //
  //         // Only pause video when promise is fulfilled
  //         videoPlayPromise?.then(() => video.pause())
  //       }
  //     })
  //   }, {
  //     root: document.querySelector('.app-images-modal__images'),
  //     rootMargin: '0px',
  //     threshold: 1.0,
  //   })
  //
  //   const nodes = document.querySelectorAll('.app-images-model-item__media-item')
  //   nodes.forEach((node) => {
  //     if ([...node.classList].includes('app-images-model-item__media-item--video')) {
  //       videoObserver.value?.observe(node)
  //     }
  //   })
  // }

  function setIntersectionObserversOnEndlessScroll() {
    endlessScrollObserver.value = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio >= 0.5 && !loadingNewImages.value && hasMorePages.value) {
          fetchNewPage()
        }
      })
    }, {
      root: document.querySelector('.app-images-modal__images'),
      rootMargin: '0px',
      threshold: 0.5,
    })

    endlessScrollObserver.value?.observe(endlessScrollElement.value)
  }

  function like(image) {
    $interactionsService.setMediaLikes(props.event.id, image.id, 1)
    image.likes += 1
  }

  function unlike(image) {
    $interactionsService.setMediaLikes(props.event.id, image.id, -1)
    image.likes -= 1
  }
</script>

<style lang="scss">
.app-images-modal {
  height: 100dvh;
  position: absolute;
  background: black;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn .2s forwards;
  transition: all .2s ease-in-out;
  -webkit-overflow-scrolling: touch;
}

.app-images-modal__controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10%;
  display: flex;
  align-items: center;
  padding: 0 8px;
  justify-content: space-between;
  color: white;
  background: black;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 75%, rgba(0, 0, 0, 0) 100%);

  @include breakpoint(desktop) {
    background: transparent;
  }
}

.app-images-modal__button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 45px;
  width: 45px;
}

.app-images-modal__button--back {
  width: auto;
  gap: 24px;
  padding: 0 0 0 12px;

  @include breakpoint(desktop) {
    padding: 0 0 0 24px;
  }
}

.app-images-modal__background {
  background: black;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.app-images-modal__images {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  overflow-x: hidden;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @include breakpoint(desktop) {
    padding-top: 10dvh;
  }
}

.app-images-modal__images::-webkit-scrollbar {
  display: none;
}

.app-images-model__media-item-wrapper:not(:has(~ .app-images-model__media-item-wrapper)) {
  @include breakpoint(desktop) {
    margin-bottom: 35dvh;
  }
}

.app-images-modal__scroll-indicator {
  position: fixed;
  bottom: 12px;
  left: 50%;

  @include breakpoint(desktop) {
    left: auto;
    right: 32px;
  }
}

.app-images-modal__text-logo {
  position: absolute;
  bottom: 16px;
  left: 5%;
  font-size: 14px;
  opacity: 0.8;

  @include breakpoint(desktop) {
    left: 32px;
  }
}

.app-images-modal__endless-scroll {
  padding: 64px 0;
}

.app-images-modal__start-slideshow {
  margin: 0 12px 0 0;

  @include breakpoint(desktop) {
    margin: 0 24px 0 0;
  }
}
</style>
