import { defineStore } from 'pinia'

export const useSlideshowSettingStore = defineStore('slideshow-settings', () => {
  const formData = ref({
    timeoutInSec: 5,
    backgroundColor: '#000000',
    showImages: true,
    showVideos: true,
  })

  function setFormData(payload) {
    formData.value = payload
  }

  return {
    formData,
    setFormData,
  }
})
