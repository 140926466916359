<template>
  <div
    v-if="authenticated"
    class="event-page"
    :style="{
      '--event-text-color': eventTextColor,
      '--event-background-color': eventBackgroundColor,
    }"
  >
    <template v-if="event">
      <fixed-ratio :width="fixedRatioWidth" :height="fixedRatioHeight">
        <event-view
          :event="event"
          :show-scroll-to-images-button="event && showGallery"
          :slideshow-button-visible="(showGallery || isEventOwner) && isMomentshareDrive"
          :event-settings-button-visible="isEventOwner"
          @scroll-to-images="scrollToImages"
          @start-slideshow="setSlideshowIsActive(true)"
          @open-event-settings="openEventSettings"
        >
          <template #app-upload>
            <app-upload
              :event="event"
            />
          </template>
        </event-view>
      </fixed-ratio>
      <client-only>
        <app-images
          v-if="event && showGallery && !slideshowIsActive"
          :event="event"
          @start-slideshow="setSlideshowIsActive(true)"
        />
        <transition name="fade-long" mode="out-in">
          <event-slideshow
            v-if="slideshowIsActive"
            :event="event"
            @close="setSlideshowIsActive(false)"
          />
        </transition>
      </client-only>
    </template>
    <div v-else-if="!event && !loading" class="event-page__error-wrapper">
      <error-state
        :title="errorTitle"
        :text="errorText"
        :button-text="$t('go_to_home')"
        :icon-name="errorIcon"
        class="event-page__unpublished-error"
        @click="goToHome"
      >
        <template v-if="!basicEventInformationHasStatusEnded" #buttons>
          <app-button
            light
            small
            @click="openLoginModal"
          >
            {{ $t('owner_login') }}
          </app-button>
        </template>
      </error-state>
    </div>
    <app-toast-wrapper />
    <app-overlay />
  </div>
</template>

<script setup>
  import { useToastStore } from '~/stores/toast'
  import { useRoute } from 'vue-router'
  import useEventProperties from '~/lib/composables/event-properties'
  import { EVENT_PROPERTY_KEY } from '~/lib/constants/event'
  import { useUserStore } from '~/stores/user'
  import { useEventsStore } from '~/stores/events'
  import { useGuestStore } from "~/stores/guest"
  import { APP_MODAL, LOGIN_MODAL } from '~/lib/constants/overlay'
  import { EVENT_STATES } from '~/lib/models/event'
  import { ROUTES } from '~/lib/constants/routes'

  definePageMeta({
    layout: 'event',
  })

  const { $eventService, $networkConnectionService, $dateTimeService, $overlayService } = useNuxtApp()
  const localePath = useLocalePath()
  const router = useRouter()
  const route = useRoute()

  const { isAuthenticated } = useUserStore()
  const { getEventById } = useEventsStore()
  const { setEventProperties, eventTextColor, eventBackgroundColor, event } = useEventProperties()
  const eventId = route.params.event
  const accessToken = route.query.auth
  const { t: $t } = useI18n()
  const loading = ref(false)
  const backgroundImageUpload = computed(() => $eventService.getEventPropertyByKey(event.value?.properties, EVENT_PROPERTY_KEY.BACKGROUND_IMAGE_UPLOAD)?.value)
  const backgroundImage = computed(() => $eventService.getEventPropertyByKey(event.value?.properties, EVENT_PROPERTY_KEY.BACKGROUND_IMAGE)?.value)
  const authenticated = ref(false)
  const slideshowIsActive = ref(false)
  const basicEventInformation = ref(null)

  const fixedRatioWidth = computed(() => {
    return window?.innerWidth
  })
  const fixedRatioHeight = computed(() => {
    return window?.innerHeight
  })

  const useDropbox = computed(() => $eventService.getEventPropertyByKey(event.value?.properties, EVENT_PROPERTY_KEY.USE_DROPBOX)?.value)
  const useGoogle = computed(() => $eventService.getEventPropertyByKey(event.value?.properties, EVENT_PROPERTY_KEY.USE_GOOGLE_DRIVE)?.value)
  const useMomentshareDrive = computed(() => $eventService.getEventPropertyByKey(event.value?.properties, EVENT_PROPERTY_KEY.HOSTING_ENABLED)?.value)
  const isMomentshareDrive = computed(() => !useGoogle.value && !useDropbox.value && useMomentshareDrive.value)
  const showGallery = computed(() => $eventService.getEventPropertyByKey(event.value?.properties, EVENT_PROPERTY_KEY.ENABLE_GALLERY)?.value)
  const isEventOwner = computed(() => isAuthenticated && !!getEventById(eventId))

  // Error state variables
  const basicEventInformationHasStatusEnded = computed(() => basicEventInformation.value?.status === EVENT_STATES.ENDED ?? false)
  const errorIcon = computed(() => basicEventInformationHasStatusEnded.value
    ? 'material-symbols:event-busy'
    : 'material-symbols:calendar-clock')
  const errorTitle = computed(() => {
    if (!basicEventInformation.value) {
      return $t('not_published_title_base')
    }

    if (basicEventInformationHasStatusEnded.value) {
      return $t('event_ended_title', { eventName: basicEventInformation.value.name })
    }

    const currentTimeStamp = new Date().getTime()
    const eventTimeStamp = basicEventInformation.value.date.getTime()

    if (eventTimeStamp > currentTimeStamp) {
      return $t('not_published_title_with_date', { eventName: basicEventInformation.value.name })
    }

    return $t('not_published_title', { eventName: basicEventInformation.value.name })
  })
  const errorText = computed(() => {
    if (!basicEventInformation.value) {
      return $t('not_published_text')
    }

    if (basicEventInformationHasStatusEnded.value) {
      return $t('event_ended_text')
    }

    const currentTimeStamp = new Date().getTime()
    const eventTimeStamp = basicEventInformation.value.date.getTime()

    if (eventTimeStamp > currentTimeStamp) {
      return $t('not_published_text_with_date', { date: $dateTimeService.getDateWithLongMonth(basicEventInformation.value.date) })
    }

    return $t('not_published_text')
  })

  const showToastMessage = async (message) => {
    await useToastStore().showToastMessage(message)
  }

  const authenticate = async () => {
    const hasToken = $eventService.guestAuthTokenForEvent(eventId)

    if (hasToken) {
      authenticated.value = true

      return
    }

    if (!accessToken) {
      return
    }

    try {
      const token = await $eventService.getGuestAuthToken(accessToken)
      useGuestStore().setToken(eventId, token)
      authenticated.value = true

      return true
    } catch (error) {
      await showToastMessage({
        text: error?.message ? error.message : error,
      })
    }
  }

  function goToHome() {
    router.push(localePath('/'))
  }

  function scrollToImages() {
    const eventLayout = document.querySelector('.event-layout')
    eventLayout?.scrollTo({ behavior: 'smooth', top: window.innerHeight })
  }

  function setSlideshowIsActive(bool = false) {
    slideshowIsActive.value = bool
  }

  function openEventSettings() {
    router.push(localePath(ROUTES.ACCOUNT_EVENTS))
  }

  function openLoginModal() {
    $overlayService.setCurrentOverlay({
      settings: {
        type: APP_MODAL,
        component: LOGIN_MODAL,
        options: { onSuccess: handleSuccessfulLogin },
      },
    })
  }

  function handleSuccessfulLogin() {
    window.location.reload()
  }

  function handleAutoPlaySlideshow() {
    if (route.query.slideshow) {
      setSlideshowIsActive(true)
    }
  }

  // asyncData
  const { error } = await useAsyncData(async () => {
    try {
      loading.value = true

      const token = await $eventService.getGuestAuthToken(accessToken)

      if (!token) {
        return
      }

      useGuestStore().setToken(eventId, token)

      event.value = await $eventService
        .getEventAsGuest(eventId, token)
        .catch(async (error) => {
          basicEventInformation.value = await $eventService
            .getBasicEventInformation(eventId, token)
            .catch(() => null)

          if (basicEventInformation.value) {
            return null
          }

          return Promise.reject(error)
        })

      if (event.value) {
        setEventProperties(event.value)
      }
    } catch (error) {
      showError({ message: $t('page_not_found'), statusCode: 404 })
    } finally {
      loading.value = false
    }
  })

  if (error.value) {
    // This will trigger an error page server side
    throw createError({ message: $t('page_not_found'), statusCode: 404 })
  }

  onMounted(() => {
    authenticate()
    setTimeout(() => {
      $networkConnectionService.startConnectionMonitor()
    }, 3000)
    handleAutoPlaySlideshow()
  })

  onBeforeUnmount(() => {
    $networkConnectionService.stopConnectionMonitor()
  })

  const seoPageTitle = computed(() => $t('seo.event_page.title', { eventName: event?.value?.name }))
  const seoPageDescription = computed(() => $t('seo.event_page.description', { eventName: event?.value?.name }))
  const headImage = computed(() => {
    if (backgroundImageUpload?.value && !backgroundImageUpload?.value?.includes('/themes')) {
      return backgroundImageUpload.value
    }

    if (backgroundImage?.value && !backgroundImage?.value?.includes('/themes')) {
      return backgroundImage.value
    }

    return 'https://momentshare.io/android-chrome-256x256.png'
  })

  useHead(
    {
      title: () => seoPageTitle.value,
      meta: [
        { hid: 'description', name: 'description', content: () => seoPageDescription.value },
        { property: 'og:url', content: () => `https://momentshare.io${route.fullPath}` },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: () => seoPageTitle.value },
        { property: 'og:image', content: () => headImage.value },
      ],
    }
  )
</script>

<style lang="scss">
  .event-page {
    background: var(--event-background-color, #fff);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .event-page__error-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    margin: auto;
  }
</style>
