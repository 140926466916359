<template>
  <div class="event-slideshow-item">
    <video
      v-if="isVideo"
      ref="videoElement"
      class="event-slideshow-item__media-item event-slideshow-item__media-item--video"
      controls
      muted
      preload="metadata"
      playsinline
      autoplay
      :poster="image.thumbFilePath"
      @loadedmetadata="handleLoadedMetadata"
      @error="handleError"
    >
      <source :src="src" type="video/mp4">
    </video>
    <img
      v-else
      ref="imageElement"
      :src="src"
      class="event-slideshow-item__media-item event-slideshow-item__media-item--image"
      referrerpolicy="no-referrer"
      @load="handleLoaded"
      @error="handleError"
    >
  </div>
</template>

<script setup>
  import { Image } from '~/lib/models/image'

  const props = defineProps({
    image: {
      type: Image,
      required: true,
    },
    slideTimeoutInMs: {
      type: Number,
      default: 5000,
    },
  })
  const emit = defineEmits(['error', 'loaded'])
  const isVideo = ref(props.image.mimeType.includes('video/'))
  const src = ref(props.image.filePath)
  const imageElement = ref(null)
  const videoElement = ref(null)

  function handleLoadedMetadata(event) {
    emit('loaded', event.target.duration * 1000)
  }

  function handleLoaded() {
    emit('loaded', props.slideTimeoutInMs)
  }

  function handleError() {
    emit('error')
  }
</script>

<style lang="scss">
  .event-slideshow-item__media-item {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>
