<template>
  <div
    class="app-images-model-item"
  >
    <div class="app-images-model-item__media-item-wrapper">
      <iframe
        v-if="isVideo && isGoogleDrive"
        ref="iframeVideo"
        allowfullscreen=""
        allow="autoplay"
        :src="videoSrc"
        class="app-images-model-item__media-item app-images-model-item__media-item--video"
      />
      <video
        v-else-if="isVideo"
        class="app-images-model-item__media-item app-images-model-item__media-item--video"
        controls
        preload="none"
        playsinline
        :poster="image.thumbFilePath"
      >
        <source :src="videoSrc" type="video/mp4">
      </video>
      <img
        v-else
        :src="image.filePath"
        class="app-images-model-item__media-item app-images-model-item__media-item--image"
        referrerpolicy="no-referrer"
        :loading="isIndexedImage ? 'eager' : 'lazy'"
      >
      <div class="app-images-model-item__controls app-images-model-item__controls--bottom">
        <button
          :disabled="downloadLoading"
          class="app-images-model-item__button"
          @click="downloadFile"
        >
          <app-spinner
            v-if="downloadLoading"
            color="white"
            dimension="24px"
            class="app-images-model-item__icon app-images-model-item__icon--downloading"
          />
          <icon
            v-else
            name="material-symbols:download"
            class="app-images-model-item__icon app-images-model-item__icon--download"
          />
        </button>
        <div class="app-images-model-item__button-wrapper">
          <transition
            name="pop"
            mode="out-in"
          >
            <button
              :key="liked ? 'like-filled' : 'like'"
              class="app-images-model-item__button app-images-model-item__button--like"
              @click="handleImageLike"
            >
              <icon
                :name="liked ? 'material-symbols:favorite' : 'material-symbols:favorite-outline'"
                class="app-images-model-item__icon app-images-model-item__icon--like"
                :class="{ 'app-images-model-item__icon--like-filled': liked }"
              />
            </button>
          </transition>
          <span class="app-images-model-item__icon-subtext">{{ image.likes }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { Image } from '~/lib/models/image'
  import { useToastStore } from '~/stores/toast'

  const { $imagesService, $interactionsService } = useNuxtApp()

  const props = defineProps({
    image: {
      type: Image,
      required: true,
    },
    eventName: {
      type: String,
      required: true,
    },
    eventId: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    imageIndex: {
      type: Number,
      required: true,
    },
    isGoogleDrive: {
      type: Boolean,
      default: false,
    },
    isMomentshareDrive: {
      type: Boolean,
      default: false,
    },
  })
  const emit = defineEmits(['like', 'unlike'])

  const downloadLoading = ref(false)
  const isVideo = ref(props.image.mimeType.includes('video/'))
  const isIndexedImage = computed(() => props.index === props.imageIndex)
  const videoSrc = computed(() => {
    if (!props.isGoogleDrive) {
      return props.image.filePath
    }

    return `https://drive.google.com/file/d/${props.image.id}/preview`
  })
  const fileName = $imagesService.mapFileNameForDownload(props.eventName, props.image)

  // Used for forcing re-compute of liked
  const likedCounter = ref(0)
  const liked = computed(() => {
    // Force re-compute
    likedCounter.value

    return $interactionsService.getMediaIsLiked(props.eventId, props.image.id)
  })

  function handleImageLike() {
    liked.value ? emit('unlike') : emit('like')
    likedCounter.value++
  }

  async function downloadFile() {
    try {
      downloadLoading.value = true
      const isGoogleVideo = videoSrc && props.isGoogleDrive
      const src = isGoogleVideo ? props.image.originalFilePath : props.image.filePath

      await $imagesService.downloadFile(src, fileName, props.image.id, props.eventId, isGoogleVideo, props.isMomentshareDrive)
    } catch (error) {
      await useToastStore().showToastMessage({
        text: error.message ? error.message : error,
      })
    } finally {
      downloadLoading.value = false
    }
  }
</script>

<style lang="scss">
  .app-images-model-item {
    height: 100dvh;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(desktop) {
      height: 85dvh;
    }
  }

  .app-images-model-item__media-item-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 70%;
    width: 100%;

    @include breakpoint(desktop) {
      max-height: none;
      height: 100%;
      width: 100%;
      padding: 5dvh 0 60px 0;
    }
  }

  .app-images-model-item__controls {
    position: absolute;
    left: 0;
    right: 0;
    background: black;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    justify-content: space-between;
    flex-shrink: 0;
  }

  .app-images-model-item__controls--top {
    top: 0;
  }

  .app-images-model-item__controls--bottom {
    bottom: -45px;

    @include breakpoint(desktop) {
      bottom: 0;
      justify-content: center;
    }
  }

  .app-images-model-item__media-item {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .app-images-model-item__button-wrapper {
    position: relative;
  }

  .app-images-model-item__button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    min-height: 46px;
    min-width: 46px;
  }

  .app-images-model-item__icon {
    width: 24px;
    height: 24px;
  }

  .app-images-model-item__icon--like {
    margin: 0 0;
  }

  .app-images-model-item__icon--like-filled {
    color: #FF3040;
  }

  .app-images-model-item__icon-subtext {
    position: absolute;
    bottom: -8px;
    font-size: 12px;
    color: white;
    left: 50%;
    transform: translateX(-50%);
  }
</style>
