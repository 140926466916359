<template>
  <div
    v-if="galleryEnabled"
    class="app-images"
    :style="{
      '--background-color': backgroundColor
    }"
  >
    <app-image
      v-for="(image, index) in images"
      :key="image.id"
      class="app-images__app-image"
      :image="image"
      @click="handleImageClicked(index)"
    />
    <transition name="fade">
      <app-images-modal
        v-if="fullScreenIsOpen"
        :event="event"
        :index="currentImageIndex"
        :is-google-drive="useGoogle"
        :is-momentshare-drive="isMomentshareDrive"
        :event-name="event.name"
        :event-id="event.id"
        @close="handleCloseFullScreen"
        @start-slideshow="handleStartSlideshow"
      />
    </transition>
    <div
      v-if="hasMorePages"
      ref="endlessScrollElement"
      class="app-images__endless-scroll"
    >
      <app-spinner class="app-images__endless-scroll-spinner" dimension="32px" />
    </div>
    <app-spinner
      v-if="loadingFirstImages"
      class="app-images__loading-spinner"
      dimension="32px"
    />
  </div>
</template>

<script setup>
  import { storeToRefs } from 'pinia'
  import { useImagesStore } from '~/stores/images'
  import { Event } from '~/lib/models/event'
  import { EVENT_PROPERTY_KEY } from '~/lib/constants/event'

  const { $imagesService, $eventService } = useNuxtApp()
  const imagesStore = useImagesStore()
  const router = useRouter()
  const route = useRoute()

  const props = defineProps({
    event: {
      type: Event,
      default: undefined,
    },
  })

  const emit = defineEmits(['start-slideshow'])

  const {
    images,
    hasMorePages,
  } = storeToRefs(imagesStore)

  const endlessScrollObserver = ref(null)
  const endlessScrollElement = ref(null)

  const fullScreenIsOpen = ref(false)
  const currentImageIndex = ref(0)
  const loadingNewImages = ref(false)
  const loadingFirstImages = ref(false)
  const backgroundColor = ref($eventService.getEventPropertyByKey(props.event.properties, EVENT_PROPERTY_KEY.BACKGROUND_COLOR)?.value)
  const useDropbox = ref($eventService.getEventPropertyByKey(props.event.properties, EVENT_PROPERTY_KEY.USE_DROPBOX)?.value)
  const useGoogle = ref($eventService.getEventPropertyByKey(props.event.properties, EVENT_PROPERTY_KEY.USE_GOOGLE_DRIVE)?.value)
  const useMomentshareDrive = ref($eventService.getEventPropertyByKey(props.event.properties, EVENT_PROPERTY_KEY.HOSTING_ENABLED)?.value)
  const galleryEnabled = ref($eventService.getEventPropertyByKey(props.event.properties, EVENT_PROPERTY_KEY.ENABLE_GALLERY)?.value)

  const isMomentshareDrive = computed(() => !useGoogle.value && !useDropbox.value && useMomentshareDrive.value)
  const imagesAndEndlessScrollElement = computed(() => !!images.value?.length && !!endlessScrollElement.value)

  watch(
    () => imagesAndEndlessScrollElement.value,
    () => setTimeout(() => setIntersectionObserversOnEndlessScroll(), 500),
    { immediate: true }
  )

  onMounted(() => {
    if (route.query.imageModal) {
      handleCloseFullScreen()
    }

    getImages()
  })

  watch(
    () => route.query.imageModal,
    async (queryValue) => {
      const index = Number(queryValue)

      if (!isNaN(index)) {
        return openFullScreen(index)
      }

      return handleCloseFullScreen()
    },
  )

  onBeforeUnmount(() => {
    removeIntersectionObserversOnEndlessScroll()
  })

  async function getImages(page = 0) {
    if (!galleryEnabled.value) return

    loadingFirstImages.value = true

    await $imagesService.getImages({ event: props.event, page })

    loadingFirstImages.value = false
  }

  function removeIntersectionObserversOnEndlessScroll() {
    endlessScrollObserver.value?.disconnect()
    endlessScrollObserver.value = null
  }

  function setIntersectionObserversOnEndlessScroll() {
    if (!endlessScrollElement.value || endlessScrollObserver.value) {
      return
    }

    endlessScrollObserver.value = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0.99 && !loadingNewImages.value && hasMorePages.value) {
          fetchNewPage()
        }
      })
    }, {
      root: document.querySelector('.event-layout'),
      rootMargin: '0px',
      threshold: 0.99,
    })

    endlessScrollObserver.value?.observe(endlessScrollElement.value)
  }

  async function fetchNewPage() {
    if (!galleryEnabled.value) return

    loadingNewImages.value = true

    await $imagesService.fetchNewPage({ event: props.event })

    await nextTick()

    loadingNewImages.value = false
  }

  function handleImageClicked(index) {
    router.push({
      query: {
        ...route.query,
        imageModal: index,
      },
    })
  }

  function openFullScreen(index) {
    currentImageIndex.value = index
    fullScreenIsOpen.value = true
  }

  function handleCloseFullScreen() {
    fullScreenIsOpen.value = false

    const currentQuery = { ...route.query }
    delete currentQuery.imageModal

    router.replace({
      query: currentQuery,
    })
  }

  function handleStartSlideshow() {
    if (!isMomentshareDrive) {
      return
    }

    handleCloseFullScreen()
    emit('start-slideshow')
  }
</script>

<style lang="scss">
  .app-images {
    width: 100%;
    display: grid;
    padding-top: 4px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;
    background: var(--background-color, #fff);

    @include breakpoint(sm) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include breakpoint(lg) {
      grid-template-columns: repeat(6, 1fr);
    }

    @include breakpoint(lgr) {
      grid-template-columns: repeat(8, 1fr);
    }
  }

  .app-images__endless-scroll {
    padding: 16px 0;
    min-height: 20px;
    grid-column: 1 / -1;
    text-align: center;
    width: 100%;
  }

  .app-images__loading-spinner {
    position: absolute;
    left: 0;
    right:0;
  }

  .app-images__app-image {
    cursor: pointer;
  }
</style>
